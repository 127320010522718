import React from 'react';
import './TechnologiesStack.scss';
import { TechnologiesContentData } from '../../CaseStudy/types';
import usePortableText from '../../../hooks/usePortableText';

interface TechnologiesStackProps {
  data: TechnologiesContentData;
  className?: string;
  secondaryClassName?: boolean;
}

const TechnologiesStack = ({ data, className = 'stack', secondaryClassName }: TechnologiesStackProps) => {
  const { renderPortableText } = usePortableText();
  const title = data?.title;
  const technologiesList = data?.technologiesList;

  return (
    <section className={className}>
      <div className={secondaryClassName ? 'outstaff__content' : 'stack__content'}>
        <div className={secondaryClassName ? 'outstaff__technologies' : 'stack__technologies'}>
          {title && <h3>{data.title}</h3>}
          {renderPortableText(technologiesList)}
        </div>
      </div>
    </section>
  );
};

export default TechnologiesStack;
