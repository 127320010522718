import * as React from 'react';
import PageLayout from '../../components/_Shared/PageLayout/PageLayout';
import '../../../node_modules/normalize.css/normalize.css';
import '../../globalStyles/_fonts.scss';
import '../../globalStyles/_styles.scss';
import CaseStudyContent from '../../components/CaseStudy';
import { graphql } from 'gatsby';
import { CaseStudyPageProps } from '../../components/CaseStudy/types';

export const query = graphql`
  query MyCaseStudy($id: String) {
    sanityCaseStudy(id: { eq: $id }) {
      _rawTechnologiesContent
      title
      cardTitle
      description
      _rawCaseStudyContent
      _rawProcessContent
      _rawResults
      slug {
        current
      }
      seo {
        title
        description
      }
      categories {
        sanityId
        title
      }
      image {
        asset {
          url
        }
      }
      caseStudyContent {
        content {
          _rawChildren
        }
        information {
          businessModel
          industry
          location
          awards {
            asset {
              url
            }
          }
        }
      }
      processContent {
        title
      }
      overview {
        title
        description
        images {
          asset {
            url
          }
        }
      }
      results {
        header
        image {
          asset {
            url
          }
        }
        description {
          children {
            text
          }
        }
      }
      whyLunka {
        title
        describe
        icon {
          header
          description
          svgIcon {
            asset {
              url
            }
          }
        }
      }
      caseStudySlider {
        title
        description
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        technologiesImage {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      caseFeaturesContent {
        title
        listContent {
          features {
            title
            description
            icon {
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      userForm {
        managerName
        managerPosition
        description
        image {
          asset {
            url
          }
        }
        openFormLinks {
          title
          image {
            caption
            alt
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const CaseStudy = (props: CaseStudyPageProps) => {
  const { title, description } = props.data.sanityCaseStudy.seo;
  const faqMarkup = props.data.sanityCaseStudy.faqMarkup;
  const { allSanityFooter } = props.data;

  return (
    <PageLayout footerData={allSanityFooter} markData={faqMarkup} title={title} description={description}>
      <CaseStudyContent data={props.data.sanityCaseStudy} />
    </PageLayout>
  );
};

export default CaseStudy;
