import * as React from 'react';
import './FeaturesToBuildSolutions.scss';
import { NewIndustryListContent } from '../types';

interface FeaturesToBuildSolutionsProps {
  data: NewIndustryListContent;
  subtitleClassName?: string;
  cardsClassName?: string;
}

const FeaturesToBuildSolutions = ({
  data,
  subtitleClassName = 'description',
  cardsClassName = 'serviceItem',
}: FeaturesToBuildSolutionsProps) => {
  const { title, listContent, secondaryListContent, listDescription, secondaryListDescription } = data;

  return (
    <section className="buildSolutions">
      <div className="buildSolutions__container">
        <h2>{title}</h2>
        <span className={subtitleClassName}>{listDescription && listDescription}</span>
        <div className="buildSolutions__contentWrapper">
          {listContent.map(({ features }, index) => {
            const { title, icon, description } = features;
            const iconSrc = icon.image.asset.url;

            return (
              <div className={cardsClassName} key={index}>
                <div className="item_head">
                  <img src={iconSrc} alt="solution icon" />
                  <span>{title}</span>
                </div>
                <p>{description}</p>
              </div>
            );
          })}
        </div>
        <>
          <span className="description">{secondaryListDescription && secondaryListDescription}</span>
          <div className="buildSolutions__secondaryContentWrapper">
            {secondaryListContent?.map(({ features }, index) => {
              const { title, icon, description } = features;
              const iconSrc = icon.image.asset.url;

              return (
                <div className={cardsClassName} key={index}>
                  <div className="item_head">
                    <img src={iconSrc} alt="solution icon" />
                    <span>{title}</span>
                  </div>
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </>
      </div>
    </section>
  );
};

export default FeaturesToBuildSolutions;
