import * as React from 'react';
import Main, { MAIN_BACKGROUND_STYLES } from '../_Shared/Main/Main';
import { BTN_STYLES } from '../_Shared/Button/Button';
import { CaseStudyPageData } from './types';
import CaseArticle from './CaseArticle/CaseArticle';
import WorkProcess from './WorkProcess/WorkProcess';
import TechnologiesStack from '../_Shared/TechnologiesStack/TechnologiesStack';
import Lines from '../_Shared/Lines/Lines';
import Overview from '../IndustriesPage/Overview/Overview';
import OurResult from '../_Shared/OurResult/OurResult';
import OurValues from '../_Shared/OurValues/OurValues';
import Slider from '../_Shared/Slider/Slider';
import YourIdea from '../_Shared/YourIdea/YourIdea';
import { navigate } from 'gatsby';
import AccordionWrapper from '../_Shared/AccordionWrapper/AccordionWrapper';
import FeaturesToBuildSolutions from '../NewIndustriesPage/FeaturesToBuildSolutions/FeaturesToBuildSolutions';

const CaseStudyContent = ({ data }: CaseStudyPageData) => {
  const { title, description, caseStudySlider } = data;
  const openForm = data.userForm;
  const caseFeature = data.caseFeaturesContent;
  const faqData = data.faqMarkup;
  const technologiesContentData = data._rawTechnologiesContent;
  const navigation = () => navigate('#caseArticle');

  return (
    <section className="work">
      <Main
        classNameButton={BTN_STYLES.BTN_PRIMARY_LARGE}
        className={MAIN_BACKGROUND_STYLES.CASE_STUDY}
        title={title}
        btnTitle="Get Started"
        bottomDescription={description}
        onClick={navigation}
      />
      <CaseArticle data={data} />
      <WorkProcess data={data} />
      <TechnologiesStack data={technologiesContentData} />
      <Lines className="line__wrapper_white" />
      <Overview data={data} />
      <OurResult data={data} />
      {caseFeature && <FeaturesToBuildSolutions data={caseFeature} cardsClassName="caseItem" />}
      <OurValues
        className="valuesSmall"
        data={data}
        buttonTitle="Hire app development team"
        classNameButton={BTN_STYLES.BTN_PRIMARY_LARGE}
      />
      <Slider data={caseStudySlider} />
      <YourIdea data={openForm} />
      {faqData.length > 0 && <AccordionWrapper data={faqData} />}
    </section>
  );
};

export default CaseStudyContent;
