import * as React from 'react';
import './Overview.scss';
import { CaseStudyPageData } from '../../CaseStudy/types';

const Overview = ({ data }: CaseStudyPageData) => {
  const title = data.overview.title;
  const images = data.overview.images;
  const description = data.overview?.description;

  const render = () => {
    return images.map(({ asset: { url } }, index: number) => {
      return (
        <div key={index} className="content_item">
          <img loading="lazy" src={url} width="572px" height="400px" alt="case study app screen" />
        </div>
      );
    });
  };

  return (
    <section className="overview">
      <div className="overview__container">
        <h2>{title}</h2>
        {description && <span>{description}</span>}
        <div className="overview__content">{render()}</div>
      </div>
    </section>
  );
};

export default Overview;
