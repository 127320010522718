import * as React from 'react';
import './WorkProcess.scss';
import usePortableText from '../../../hooks/usePortableText';
import { CaseStudyPageData } from '../types';

const WorkProcess = ({ data }: CaseStudyPageData) => {
  const title = data.processContent.title;
  const { leftColumn, rightColumn } = data._rawProcessContent;
  const { renderPortableText } = usePortableText();

  return (
    <div className="workProcess">
      <div className="workProcess__container">
        <h2>{title}</h2>
        <div className="workProcess__wrapper">
          <div className="workProcess__leftColumn">{renderPortableText(leftColumn)}</div>
          <div className="workProcess__rightColumn">{renderPortableText(rightColumn)}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
