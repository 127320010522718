import * as React from 'react';
import './CaseArticle.scss';
import usePortableText from '../../../hooks/usePortableText';
import { CaseStudyPageData } from '../types';

const CaseArticle = ({ data }: CaseStudyPageData) => {
  const { renderPortableText } = usePortableText();
  const caseStudyData = data.caseStudyContent;
  const caseStudyLogo = data.image.asset.url;
  const { location, industry, businessModel, awards } = caseStudyData.information;
  const caseStudyContent = data._rawCaseStudyContent.content;
  const isAwards = awards.length > 0;

  return (
    <div id="caseArticle" className="caseArticle">
      <div className="caseArticle__container">
        <div className="caseArticle__image">
          <img src={caseStudyLogo} width="470px" height="auto" alt="case logo" />
        </div>
        <div className="caseArticle__article">
          <div className="caseArticle__info">
            <div className="location">
              <span>Location</span>
              <p>{location}</p>
            </div>
            <div className="industry">
              <span>Industry</span>
              <p>{industry}</p>
            </div>
            <div className={isAwards ? 'model' : 'awards'}>
              <span>Business Model</span>
              <p>{businessModel}</p>
            </div>
            {isAwards && (
              <div className="awards">
                <span>Awards behance</span>
                <div className="awards_wrapper">
                  {awards.map(({ asset }, index: number) => {
                    const { url } = asset;

                    return (
                      <div className="award_label" key={index}>
                        <img src={url} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="caseArticle__text">{renderPortableText(caseStudyContent)}</div>
        </div>
      </div>
    </div>
  );
};

export default CaseArticle;
