import React from 'react';
import './OurResult.scss';
import usePortableText from '../../../hooks/usePortableText';
import { CaseStudyPageData } from '../../CaseStudy/types';

const OurResult = ({ data }: CaseStudyPageData) => {
  const resultText = data._rawResults.description;
  const value = data.results;
  const image = value.image.asset.url;
  const title = value.header;
  const { renderPortableText } = usePortableText();

  return (
    <section className="ourResults">
      <div className="ourResults__container">
        <img loading="lazy" src={image} width="320px" height="320px" alt="Git fork" />
        <div className="ourResults__text">
          <h3>{title}</h3>
          {renderPortableText(resultText)}
        </div>
      </div>
    </section>
  );
};

export default OurResult;
